.main-chart {
  padding: 0px 0px 0px 0px;
}
.graph-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 750px;
  padding: 53px 32px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: sans-serif;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 43px;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 4%;
  text-align: right;
  align-items: flex-end;
}

.y-axis {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}
.y-label {
  position: absolute;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
  white-space: nowrap;
  margin-right: -79px;
  font-size: 20px;
  font-weight: bold;
  left: -112px;
  margin-top: 2%;
  font-family: "Poppins", sans-serif;
}

.y-axis .label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18%;
  padding: 0 10px;
  box-sizing: border-box;
}

.y-axis .label:first-child,
.x-axis .label:last-child {
  border: none;
}

.label {
  font-size: 1.25rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
}

.chart {
  position: relative;
  width: 100%;
  height: 100%;
}

.x-axis {
  justify-content: space-between;
  width: 100%;
  height: 10%;
}

.x-axis .label {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 33.33%;
  padding: 0 40px;
  box-sizing: border-box;
}

.grid-container {
  width: 100%;
  height: 94%;
  display: flex;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
}

.outer-container {
  height: 45rem;
  border: 1px solid #bcb7b7;
  border-radius: 1rem;
  width: 90%;
  margin: auto;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}
.log {
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: bold;
}

.grid {
  width: 33.33%;
  height: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.grid1 {
  border-left: 3px solid black;
  border-bottom: 1px dashed #aaa;
  border-right: 1px dashed #aaa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #aaa;
  border-right: 1px dashed #aaa;
}

.grid3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #aaa;
}

.grid4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 3px solid black;
  border-bottom: 1px dashed #aaa;
  border-right: 1px dashed #aaa;
}

.grid5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #aaa;
  border-right: 1px dashed #aaa;
}

.grid6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed #aaa;
}

.grid7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid black;
  border-left: 3px solid black;
  border-right: 1px dashed #aaa;
}

.grid8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid black;
  border-right: 1px dashed #aaa;
}

.grid9 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 3px solid black;
}

.circle-container {
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
}

.text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.circle-text-container {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.circle-text {
  font-weight: bold;
  font-size: 15px;
  order: 1;
  margin: 0px 0px 0px 10px;
}

.circle-date {
  font-size: 12px;
  order: 2;
  margin: 0px 25px;
}
.circle-Box {
  width: 100%;
}

div#exampleModalToggle {
  backdrop-filter: blur(5px);
  background-color: rgba(20, 29, 44, 0.84);
  box-sizing: border-box;

  right: 0;
}
.modal {
  position: fixed;

  z-index: 1060;

  display: none;
  width: auto;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.circle-Category {
  font-size: 14px;
  font-weight: bold;
}
@media (max-width: 1400) {
  .modal {
  }
}
@media (max-width: 600px) {
  .main-chart {
    padding: 0px 15px 0px 15px;
  }
  .outer-container {
    width: auto;
    overflow-x: scroll;
  }
  .graph-container {
    width: auto;
    overflow-x: scroll;
    padding: 0px 0px 0px 0px;
  }
  .chart {
    width: auto;
  }

  .x-axis .label {
    justify-content: center;
    float: left;
    width: 220px;
    padding: 0 20px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    position: relative;
    left: 0px;
  }

  .y-axis {
    padding: 0px 15px 0px 15px;
  }
}
.graph_inner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.graph_all {
  transform: rotate(0deg);

  transform-origin: center;
  text-align: center;
}
