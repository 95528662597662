.asset-form {
  @apply flex flex-col justify-center items-center w-fit;
}

.asset-form-row {
  @apply flex w-full flex-wrap justify-between mx-3;
  align-items: center;
}

.asset-form-section {
  @apply flex flex-col items-start justify-center my-2;
}

.modal-text {
  font-size: 22px;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.assetmodal {
  max-width: 706px;
  height: 510px;
}

.modalclose1 {
  background-color: white;
  color: black !important;
}

.modalclose1text {
  font-weight: bold;
  font-size: 24px;
}

.assetNamelabel {
  margin-bottom: 8px;
  font-size: 15px;
}

.assetName_input {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.assetClass_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.select_aseestclass {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.taxTreatment_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.select_taxTreatment {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.duration_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.select_duration {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.amountInvested_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.amountinvest_input {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.fixed_input {
  width: 280px;
  height: 42px;
  border-radius: 7px;
  float: left !important;
  color: rgba(20, 29, 44, 0.84);
  font-weight: 600;
}

.FixedIncome_label {
  margin-bottom: 8px;
  font-size: 15px;
}

.field_error {
  font-size: 13px;
  color: red;
  margin-top: 1px;
}

.asset_list_h4 {
  margin-left: 25px;
  font-family: sans-serif;
  margin-top: 10px;
}
